import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./info.scss";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import { DarkModeContext } from "../../../Context/darkModeContext";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";

const Info = () => {
  const { pathname } = useLocation();
  const [active, setActive] = useState();
  const { dispatch, darkMode } = useContext(DarkModeContext);

  const handleDarkMode = () => {
    dispatch({ type: "TOGGLE" });
  };

  const isHomePage = pathname === "/"; // Change "/" to your home page path if different

  if (!isHomePage) {
    return null;
  }

  return (
    <div className="info">
      <div className="info-wrapper container">
        <div className="info-text">
          <div className="location">
            <span>
              {" "}
              <RoomOutlinedIcon />{" "}
            </span>
            <span>Hauenriede 39, 29525 Uelzen</span>
          </div>
          <div className="mobile">
            <span>
              {" "}
              <PhoneIphoneIcon />{" "}
            </span>
            <span>0581 94879080</span>
          </div>
          <div className="email">
            <span>restaurantopatija23@gmail.com</span>
          </div>
        </div>
        <div className="info-text-icon">
          <div className="location">
            <span class="material-icons-outlined">
              {" "}
              <span onClick={handleDarkMode}>
                {darkMode ? <LightModeOutlinedIcon /> : <DarkModeIcon />}
              </span>
            </span>
          </div>
          <div className="location">
            <span class="material-icons-outlined">
              {" "}
              <InstagramIcon />{" "}
            </span>
          </div>
          <div className="mobile">
            <span class="material-icons-outlined">
              {" "}
              <FacebookIcon />{" "}
            </span>
          </div>
          <div className="email">
            <span class="material-icons-outlined">
              {" "}
              <TwitterIcon />{" "}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
