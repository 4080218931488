import React from "react";
import "./gallery.scss";
import gallery_1 from "../../../static/gallery-1.webp";
import gallery_2 from "../../../static/gallery-2.webp";
import gallery_3 from "../../../static/gallery-3.webp";
import gallery_4 from "../../../static/gallery-4.webp";
import gallery_5 from "../../../static/gallery-5.webp";
import gallery_6 from "../../../static/gallery-6.webp";

const images = [
  { original: gallery_1 },
  { original: gallery_2 },
  { original: gallery_3 },
  { original: gallery_4 },
  { original: gallery_5 },
  { original: gallery_6 },
];

const Gallery = () => {
  return (
    <div className="gallery">
      <div className="container">
        <div className="gallery__box">
          {images.map((image, i) => (
            <div className="gallery__images" key={i}>
              <img
                className="box-image"
                src={image.original}
                alt={`Gallery ${i + 1}`}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Gallery;
